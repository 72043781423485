<template>
  <div>
    <v-form ref="form"
            v-model="valid"
            lazy-validation>
      <v-row class="my-0">
        <v-col cols="12"
               sm="8"
               md="7"
               lg=8>
          <v-card-subtitle class="pa-0 py-4">
            <LocalizedLabel>general</LocalizedLabel>
          </v-card-subtitle>
          <v-row>
            <v-col>
              <v-text-field v-model="selectedPromotionDetails.displayName"
                            :label="translations.promotionName"
                            outlined
                            dense
                            :disabled="isOngoing"
                            :rules="promotionNameRules"
                            required>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <FilterMultiselect :items="companyGames.data"
                                 :text="'displayName'"
                                 :value="'id'"
                                 :label="translations.gameName"
                                 :name="'games'"
                                 :hideDetails="false"
                                 :rules="selectAllRules"
                                 :required="true"
                                 :returnObject="true"
                                 :customSlots="true"
                                 :disabled="isOngoing"
                                 :querySelected="selectedPromotionDetails.selectedGame"
                                 @updateSelectAll="updateSelectAll" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <FilterMultiselect :items="filterEmptyTags"
                                 :text="'name'"
                                 :value="'name'"
                                 :label="translations.selectPlayersTag"
                                 :name="'tags'"
                                 :hideDetails="false"
                                 :rules="selectAllRules"
                                 :required="true"
                                 :disabled="isOngoing"
                                 :querySelected="selectedPromotionDetails.tagsArray"
                                 @updateSelectAll="updateSelectAll" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   sm="6">
              <PromotionsDateTimePicker :dateSelected="selectedPromotionDetails.startTime"
                                        :label="translations.startDateTime"
                                        type="start"
                                        startDate="true"
                                        :rules="startTimeRules"
                                        :disabled="isOngoing"
                                        @updateDateRange="updateDateRange" />
            </v-col>
            <v-col cols="12"
                   sm="6">
              <PromotionsDateTimePicker :dateSelected="selectedPromotionDetails.endTime"
                                        :label="translations.endDateTime"
                                        type="end"
                                        :dateStart="dateStart"
                                        :rules="endTimeRules"
                                        :disabled="!dateStart"
                                        endDate="true"
                                        @updateDateRange="updateDateRange" />
            </v-col>
          </v-row>
          <v-card-subtitle class="pa-0 py-4">
            <LocalizedLabel>additionalPromoSettings</LocalizedLabel>
          </v-card-subtitle>
          <v-row>
            <v-col>
              <v-select v-model="selectedPromotionDetails.promotionTypeObject"
                        :items="mappedPromotionTypes"
                        item-text="name"
                        item-value="value"
                        :label="translations.promotionType"
                        outlined
                        dense
                        :disabled="isOngoing || detailsModalConfig.editPromotion"
                        offset-y
                        return-object
                        :rules="requiredRules"
                        required
                        @blur="getPromotionEstimates()">
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   sm="6"
                   v-if="isPromotionTypeRounds">
              <v-text-field v-model="selectedPromotionDetails.prizeCountPerPlayer"
                            :label="translations.numOfRounds"
                            outlined
                            dense
                            type="number"
                            :disabled="isOngoing"
                            :rules="numOfRoundsRules"
                            required
                            @blur="getPromotionEstimates()">
              </v-text-field>
            </v-col>
            <v-col cols="12"
                   sm="6">
              <v-text-field v-model="selectedPromotionDetails.amountPerPlayer"
                            :label="getAmountLabel"
                            outlined
                            dense
                            type="number"
                            @input="onInput"
                            :disabled="isOngoing"
                            :rules="amountPerPlayerRules(selectedPromotionDetails.selectedGame)"
                            ref="amount"
                            required
                            @blur="getPromotionEstimates()">
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-divider vertical
                   class="mt-n4 px-1 mx-0 verticalDivider"></v-divider>
        <v-col cols="12"
               sm="4"
               md="5"
               lg="4"
               class="pa-0 ml-n3 pl-3 mt-n4">
          <v-card flat
                  width="100%"
                  v-for="(item, index) in infoHeaders"
                  :key="index">
            <v-card-subtitle class="caption ml-1"
                             v-html="item.label">
            </v-card-subtitle>
            <div class="ml-5 mt-n3 caption">
              <span>
                {{ estimate[item.key] || 0 }}
              </span>
            </div>
            <v-divider class="mt-4 ml-n3"></v-divider>
          </v-card>
        </v-col>
      </v-row>
      <transition name="slide"
                  appear>
        <v-card class="fotterCard"
                tile
                elevation="10"
                v-if="detailsModalToggler">
          <v-row class="px-8 ma-0">
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-btn text
                     small
                     class="text-none"
                     width="120"
                     @click="cancelButton()">
                <LocalizedLabel>cancel</LocalizedLabel>
              </v-btn>
              <v-btn small
                     color="primary"
                     class="background--text text-none"
                     width="120"
                     :key="actionKey"
                     :disabled="disabledAction"
                     @click.once="actionButton()">
                <LocalizedLabel>save</LocalizedLabel>
              </v-btn>
            </v-card-actions>
          </v-row>
        </v-card>
      </transition>
    </v-form>
    <v-dialog max-width="700"
              v-model="dialog">
      <v-card class="pa-6">
        <v-card-title class="pa-0 ml-2 my-4 subtitle--text">
          <LocalizedLabel>createPromotion</LocalizedLabel>
        </v-card-title>
        <v-row>
          <v-col cols="12"
                 sm="6"
                 v-for="(detail, index ) in generalDetails"
                 :key="index">
            <v-card-subtitle class="caption font-weight-light text-uppercase pa-0 ml-4">
              {{ detail.label }}
            </v-card-subtitle>
            <v-card-title class="body-2 font-weight-regular promoPreview pa-0 ml-4 mt-2">
              {{ selectedPromotionDetails[detail.value] }}
            </v-card-title>
          </v-col>
        </v-row>
        <v-card-actions class="justify-end">
          <v-btn color="primary"
                 text
                 @click="dialog = false">
            <LocalizedLabel>back</LocalizedLabel>
          </v-btn>
          <v-btn color="primary"
                 text
                 :key="actionKey"
                 @click.once="createPromotion()">
            <LocalizedLabel>create</LocalizedLabel>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="500"
              v-model="promotionErrorDialog">
      <v-card class="pa-6">
        <v-card-title class="pa-0 ml-2 my-4 subtitle--text">
          <LocalizedLabel>promotionGamesInvalidSettings</LocalizedLabel>
        </v-card-title>
        <v-data-table :headers="filteredPromotionErrorHeaders"
                      :items="promotionErrorList.data"
                      hide-default-footer
                      class="elevation-0">
          <template v-slot:item.invalidValue="{ item }">
            <span class="error--text">{{ item.invalidValue }}</span>
          </template>
          <template v-slot:item.maxValidValue="{ item }">
            <span class="success--text">{{ item.maxValidValue }}</span>
          </template>
          <template v-slot:item.minValidValue="{ item }">
            <span class="success--text">{{ item.minValidValue }}</span>
          </template>
        </v-data-table>
        <v-card-actions class="justify-end">
          <v-btn color="primary"
                 text
                 @click="promotionErrorDialog = false">
            <LocalizedLabel>back</LocalizedLabel>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  assign,
  cloneDeep,
  filter,
  find,
  isEqual,
  join,
  lowerFirst,
  map,
  pick,
} from 'lodash';
import {
  arrayLength,
  isBeforeDate,
  isBeforeNow,
  isEntered,
  isLarger,
  isSmaller,
  isValidAmount,
  isoToDate,
  nameLength,
  noDecimal,
  roundAmountsInput,
  roundDecimals,
} from '@/utility';
import FilterMultiselect from '@/components/FilterMultiselect';
import PromotionsDateTimePicker from '@/components/PromotionsDateTimePicker';

export default {
  name: 'promotionDetailsEdit',
  components: {
    FilterMultiselect,
    PromotionsDateTimePicker,
  },
  data() {
    const locale = this.$store.getters.translations;
    return {
      dateStart: null,
      dateEnd: null,
      dialog: false,
      valid: true,
      amountPerPlayer: 0,
      numOfPlayers: 0,
      actionKey: 0,
      selectedPromotionDetails: {},
      disabledAction: false,
      estimate: {},
      promotionErrorDialog: false,
      promotionErrorHeaders: [
        {
          text: locale.gameName,
          align: 'start',
          sortable: false,
          value: 'tenantGameDisplayName',
        }, {
          text: locale.invalidValue,
          align: 'end',
          sortable: false,
          value: 'invalidValue',
        }, {
          text: locale.minValidValue,
          align: 'end',
          sortable: false,
          value: 'minValidValue',
        }, {
          text: locale.maxValidValue,
          align: 'end',
          sortable: false,
          value: 'maxValidValue',
        },
      ],
      infoHeaders: [
        {
          label: locale.moneyGiven,
          key: 'givenAmount',
        }, {
          label: locale.numOfPlayers,
          key: 'numOfPlayers',
        }, {
          label: `${locale.numOfRoundsEstimated}</br>${locale.expectedCase}`,
          key: 'expectedCase',
        }, {
          label: `${locale.numOfRoundsEstimated}</br>${locale.worstCase}`,
          key: 'worstCase',
        },
      ],
      payloadPickFilter: [
        'amountPerPlayer',
        'bonusType',
        'currency',
        'displayName',
        'endTime',
        'multiplier',
        'playerTags',
        'promotionType',
        'startTime',
        'tenantGames',
      ],
      generalDetails: [
        {
          label: locale.promotionName,
          value: 'displayName',
        }, {
          label: locale.startDateTime,
          value: 'dateStart',
        }, {
          label: locale.promotionType,
          value: 'promotionTypeName',
        }, {
          label: locale.endDateTime,
          value: 'dateEnd',
        }, /* {
          label: locale.bonusType, leave for future development
          value: 'bonusType',
        },  */
        {
          label: locale.gameForPromotion,
          value: 'gameName',
        }, {
          label: locale.amountPerPlayer,
          value: 'amountForDisplay',
        }, {
          label: locale.numOfPlayers,
          value: 'numOfPlayers',
        }, {
          label: locale.moneyGiven,
          value: 'moneyGiven',
        },
      ],
    };
  },
  methods: {
    ...mapActions([
      'loadData',
      'createData',
      'setSelectedCompany',
      'toggleDetailsModal',
      'updateData',
    ]),
    async actionButton() {
      await this.getPromotionEstimates();
      if (this.$refs.form.validate()) {
        const payload = {
          tenantGames: this.formatGameDetails('id'),
          gameName: this.formatGameDetails('displayName'),
          amountPerPlayer: roundAmountsInput(this.selectedPromotionDetails.amountPerPlayer),
          prizeCountPerPlayer: roundAmountsInput(this.selectedPromotionDetails.prizeCountPerPlayer),
          promotionType: this.selectedPromotionDetails.promotionTypeObject.value,
          amountForDisplay: this.amountForDisplay,
          // check promotion details when changing
          promotionTypeName: this.selectedPromotionDetails.promotionTypeObject.name,
          startTime: this.dateStart,
          endTime: this.dateEnd,
          bonusType: 'FreeRounds',
          multiplier: 1,
          currency: this.$route.query.currency,
          dateStart: isoToDate(this.dateStart),
          dateEnd: isoToDate(this.dateEnd),
          tenantId: this.$route.query.tenantId,
          moneyGiven: this.estimate.givenAmount || 0,
          numOfPlayers: this.estimate.numOfPlayers || 0,
        };
        assign(this.selectedPromotionDetails, payload);
        if (this.detailsModalConfig.editPromotion) {
          this.editPromotion();
        } else {
          this.dialog = true;
          this.actionKey += 1;
        }
      } else {
        this.actionKey += 1;
      }
    },
    cancelButton() {
      this.toggleDetailsModal(false);
      this.$refs.form.reset();
    },
    async createPromotion() {
      if (this.$refs.form.validate()) {
        this.payloadPickFilter.push('tenantId');
        this.setPayloadPerType();
        const response = await this.createData({
          data: pick(this.selectedPromotionDetails, this.payloadPickFilter),
          path: 'promotions',
          notification: 'promotionCreatedLabel',
          notificationError: 'promotionGamesInvalidSettings',
          promotionError: true,
        });
        if (response) {
          this.dialog = false;
          this.toggleDetailsModal(false);
          this.actionKey += 1;
        }
      } else {
        this.dialog = false;
      }
    },
    async editPromotion() {
      if (this.$refs.form.validate()) {
        if (this.isOngoing) {
          this.payloadPickFilter = 'endTime';
        } else {
          this.getPromotionType();
          this.setPayloadPerType();
        }
        const response = await this.updateData({
          data: pick(this.selectedPromotionDetails, this.payloadPickFilter),
          path: `promotions/${this.$route.params.promotionId}`,
          notification: 'promotionEditedLabel',
          notificationError: 'promotionGamesInvalidSettings',
          promotionError: true,
        });
        if (response) {
          this.$router.replace({
            params: {
              promotionName: this.selectedPromotionDetails.displayName,
            },
            query: this.$route.query,
          }, () => { });
          this.toggleDetailsModal(false);
          this.actionKey += 1;
        }
      }
    },
    onInput(e) {
      this.amountPerPlayer = e;
    },
    updateSelectAll(name, value) {
      if (name === 'tags') {
        this.selectedPromotionDetails.playerTags = map(value, (tag) => ({ name: tag }));
      } else if (name === 'games') {
        this.selectedPromotionDetails.selectedGame = value;
        this.validateAmount(value);
      }
      this.getPromotionEstimates();
    },
    async getPromotionEstimates() {
      const payload = this.getEstimatePayload();
      if (payload && this.$refs.form.validate()) {
        const response = await this.createData({
          data: payload,
          path: 'backoffice/promotion-estimates',
        });
        if (response) {
          this.estimate = {
            givenAmount: roundDecimals(response.data.givenAmount),
            numOfPlayers: response.data.numOfPlayers,
            expectedCase: response.data.numOfRoundsToProfit.expectedCase,
            worstCase: response.data.numOfRoundsToProfit.worstCase || this.translations.invalid,
          };
        }
      } else {
        this.estimate = {};
      }
    },
    getEstimatePayload() {
      const payload = {
        playerTags: this.selectedPromotionDetails.playerTags || [],
        tenantId: this.$route.query.tenantId,
        tenantGames: this.formatGameDetails('id') || [],
        amountPerPlayer: roundAmountsInput(this.selectedPromotionDetails.amountPerPlayer),
        multiplier: 1,
        prizeCountPerPlayer: this.selectedPromotionDetails.prizeCountPerPlayer,
        currency: this.$route.query.currency,
        promotionType: this.getPromotionType(),
      };
      if (!this.isPromotionTypeRounds || isEqual(this.selectedPromotionDetails.prizeCountPerPlayer, '')) {
        delete payload.prizeCountPerPlayer;
      }
      if (payload.playerTags.length
        && payload.tenantGames.length
        && payload.amountPerPlayer
        && payload.multiplier) {
        return payload;
      }
      return false;
    },
    getPromotionType() {
      return this.selectedPromotionDetails.promotionTypeObject
        ? this.selectedPromotionDetails.promotionTypeObject.value : 'ManualBonus';
    },
    setPayloadPerType() {
      if (this.isPromotionTypeRounds) {
        this.payloadPickFilter.push('prizeCountPerPlayer');
      }
    },
    updateDateRange(value, type) {
      if (type === 'start') {
        this.dateStart = value;
        this.selectedPromotionDetails.startTime = value;
      } else {
        this.dateEnd = value;
        this.selectedPromotionDetails.endTime = value;
      }
    },
    formatGameDetails(value) {
      if (value === 'displayName') {
        return join(map(this.selectedPromotionDetails.selectedGame, value), ', ');
      }
      return map(this.selectedPromotionDetails.selectedGame, (game) => ({ [value]: game[value] }));
    },
    validateAmount(value) {
      this.amountPerPlayerRules(value);
      this.$nextTick(() => {
        this.$refs.amount.validate();
      });
    },
    amountPerPlayerRules(gamesList) {
      return [
        isEntered({}, this.translations.requiredErrorLabel),
        isSmaller(1000000, this.translations.maxAmountPerPlayerErrorLabel),
        isLarger(0, this.translations.negativeAmountError),
        isValidAmount(gamesList,
          this.isPromotionTypeRounds,
          this.translations.minAmountErrorLabel,
          this.translations.maxAmountErrorLabel),
      ];
    },
  },
  computed: {
    ...mapGetters([
      'bootstrap',
      'companies',
      'companyGames',
      'detailsModalConfig',
      'detailsModalToggler',
      'games',
      'promotionDetails',
      'promotionErrorList',
      'selectedCompany',
      'tags',
      'translations',
    ]),
    amountForDisplay() {
      const amount = this.selectedPromotionDetails.amountPerPlayer;
      const count = this.selectedPromotionDetails.prizeCountPerPlayer;
      return roundAmountsInput(this.isPromotionTypeRounds ? amount * count : amount);
    },
    getAmountLabel() {
      return this.isPromotionTypeRounds
        ? this.translations.amountPerRound : this.translations.amountPerPlayer;
    },
    isOngoing() {
      return this.selectedPromotionDetails.status === 'ONGOING';
    },
    isPromotionTypeRounds() {
      return (this.selectedPromotionDetails.promotionTypeObject
        && this.selectedPromotionDetails.promotionTypeObject.value === 'ManualBonusRounds');
    },
    mappedPromotionEdit() {
      if (this.detailsModalConfig.editPromotion) {
        const list = map(this.promotionDetails, (details) => ({
          ...details,
          selectedGame: details.tenantGames,
          promotionTypeObject: find(this.mappedPromotionTypes, ['value', details.promotionType]),
          tagsArray: map(details.playerTags, 'name'),
        }));
        return list[0];
      }
      return {};
    },
    mappedPromotionTypes() {
      return map(this.bootstrap.promotionTypes,
        (type) => ({ name: this.translations[lowerFirst(type)], value: type }));
    },
    filteredPromotionErrorHeaders() {
      return filter(this.promotionErrorHeaders, (header) => (this.isPromotionTypeRounds ? header.value !== 'minValidValue' : header.value !== 'maxValidValue'));
    },
    startTimeRules() {
      if (this.isOngoing) {
        return [true];
      }
      return [
        (date) => (date || '').length >= 1 || this.translations.selectDateErrorLabel,
        () => !isBeforeNow(this.selectedPromotionDetails.startTime)
          || this.translations.dateTimeErrorLabel,
      ];
    },
    endTimeRules() {
      return [
        (date) => (date || '').length >= 1 || this.translations.selectDateErrorLabel,
        () => !isBeforeNow(this.selectedPromotionDetails.endTime)
          || this.translations.dateTimeErrorLabel,
        () => isBeforeDate(this.selectedPromotionDetails.startTime,
          this.selectedPromotionDetails.endTime)
          || this.translations.endTimeGreaterErrorLabel,
      ];
    },
    promotionNameRules() {
      return [
        isEntered(this.selectedPromotionDetails.displayName,
          this.translations.requiredErrorLabel),
        nameLength(this.selectedPromotionDetails.displayName,
          this.translations.nameLengthErrorLabel),
      ];
    },
    requiredRules() {
      return [
        isEntered({}, this.translations.requiredErrorLabel),
      ];
    },
    selectAllRules() {
      return [
        arrayLength(this.translations.requiredErrorLabel),
      ];
    },
    numOfRoundsRules() {
      return [
        isSmaller(101, this.translations.maxNumberOfRoundsErrorLabel),
        isEntered({}, this.translations.requiredErrorLabel),
        noDecimal(this.translations.noDecimalsAllowedErrorLabel),
      ];
    },
    filterEmptyTags() {
      return filter(this.tags.data, 'numOfPlayers');
    },
  },
  watch: {
    detailsModalToggler(newValue) {
      if (!newValue) {
        this.$refs.form.reset();
      }
    },
    tags() {
      if (this.detailsModalConfig.editPromotion) {
        this.getPromotionEstimates(this.selectedPromotionDetails.tagsArray);
        this.amountPerPlayer = this.selectedPromotionDetails.amountPerPlayer;
      }
    },
    promotionErrorList(newValue) {
      this.promotionErrorDialog = newValue.dialog;
      this.actionKey += 1;
    },
    selectedPromotionDetails: {
      handler(newValue) {
        if (this.detailsModalConfig.editPromotion) {
          if (isEqual(newValue, this.mappedPromotionEdit)) {
            this.disabledAction = true;
          } else {
            this.disabledAction = false;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.loadData({
      path: `tenants/${this.$route.query.tenantId}/games?size=100&hidePromotionDisabled=true`,
      name: 'company_games',
    });
    this.loadData({
      path: `tenants/${this.$route.query.tenantId}/players/tags?size=100&currency=${this.$route.query.currency}`,
      name: 'tags',
    });
    if (!this.selectedCompany.id) {
      this.setSelectedCompany(find(this.companies, { id: this.$route.query.tenantId }));
    }
    this.selectedPromotionDetails = cloneDeep(this.mappedPromotionEdit);
  },
};
</script>

<style lang="scss" scoped>
.verticalDivider {
  // full height of the viewport minus the distance from the top plus 1px for good measure
  min-height: calc(100vh - 97px);
  // height of the footer
  margin-bottom: -44px;
}

.promoPreview {
  word-break: keep-all;
}
</style>
